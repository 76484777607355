<template>
    <div class="d-flex align-items-center justify-content-between bg-silver-fade w-100 rounded border-start border-5" :class="[ (msg.quotedMsg?.fromMe || msg.quotedMsg?.id?.fromMe) ? 'border-success' : 'border-primary', msg.quotedMsg.type == 'chat' ? 'pe-2' : 'pe-1 py-1' ]"  style="font-size: 0.9em" role="button" @click="goToMessage(msg.quotedMsg)" v-if="msg">
        <div class="ms-2 d-flex flex-column">
            <div class="fw-bold" :class="(msg.quotedMsg?.fromMe || msg.quotedMsg?.id?.fromMe) ? 'text-success' : 'text-primary'">
                {{ sender }}
            </div>
            <div v-if="(msg.quotedMsg.type == 'chat' || msg.quotedMsg.type == 'text') && !msg.quotedMsg.mimetype?.startsWith('image')">
                {{ msg.quotedMsg?.body }}
            </div>
            <div class="d-flex flex-column" v-else-if="msg.quotedMsg.type == 'image' || msg.quotedMsg.type == 'video' || msg.quotedMsg.mimetype?.startsWith('image')">
                <img class="rounded" v-bind:src="hasFullPath(msg.quotedMsg.mediaURL)" style="max-width:100%; height: auto;" v-if="msg.quotedMsg.mediaURL" />
                <img class="rounded" v-bind:src="`data:image/jpeg;base64,${msg.quotedMsg?.body}`" style="max-width:100%; height: auto;" v-else/>
                {{ msg.quotedMsg?.caption }}
            </div>
            <div v-else-if="msg.quotedMsg.type == 'ptt' || msg.quotedMsg.type == 'audio'">
                Áudio
            </div>
            <div v-else-if="msg.quotedMsg.type == 'application' || msg.quotedMsg.type == 'file'">
                <div class="d-flex align-items-center gap-1 rounded text-decoration-none">
                    <div v-if="msg.quotedMsg.filename" class="text-white">{{msg.quotedMsg.filename}}</div>
                    <b-icon 
                        :icon="(fileExt(msg.quotedMsg.caption)=='csv' || fileExt(msg.quotedMsg.caption)=='xlsx') ? 'file-earmark-spreadsheet-fill' : 'file-earmark-fill'" 
                        :class="{ 'text-success': (fileExt(msg.quotedMsg.caption)=='csv' || fileExt(msg.quotedMsg.caption)=='xlsx'), 'text-danger': fileExt(msg.quotedMsg.caption)=='pdf'}" 
                        font-scale="1"
                    ></b-icon>
                    <div>{{ msg.quotedMsg.caption }}</div>
                </div>
            </div>
            <div v-else-if="msg.quotedMsg.type === 'quote_product'">
                <div>
                    {{ msg.quotedMsg.header?.text }}
                </div>
                <div>
                    {{ msg.quotedMsg.body?.text }}
                </div>
            </div>
            <div v-else-if="msg.quotedMsg.type === 'product_list'">
                <div v-if="msg.quotedMsg.body || msg.quotedMsg.bodyAux">
                    <b-row class="m-0 p-1">
                        <b-col class="p-0" cols="4">
                            <b-img class="w-100 rounded-start" style="max-height: 8em;" :src="msg.quotedMsg.body?.imageURL || msg.quotedMsg.bodyAux.imageURL" v-if="msg.quotedMsg.body?.imageURL || msg.quotedMsg.bodyAux.imageURL" />
                            <b-skeleton-img class="rounded-start pe-none" no-aspect animation="" v-else />
                        </b-col>
                        <b-col class="d-flex flex-column justify-content-center p-2 bg-secondary bg-opacity-10 rounded-end">
                            <div class="fw-semibold">
                                {{ msg.quotedMsg.body?.header.text || msg.quotedMsg.bodyAux.header.text }}
                            </div>
                            <div class="smaller-text text-secondary">
                                {{ msg.quotedMsg.body?.productList.length || msg.quotedMsg.bodyAux.productList.length }} {{ 'item' | pluralize(msg.quotedMsg.body?.productList?.length || msg.quotedMsg.bodyAux.productList?.length) }}
                            </div>
                        </b-col>
                    </b-row>
                    <div class="p-1">
                        {{ msg.quotedMsg.body?.body.text || msg.quotedMsg.bodyAux.body.text }}
                    </div>
                </div>
                <div v-else>
                    Lista de Produtos
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props:[
        'msg', 'client', 'mediaURL'
    ],
    data() {
        return {
        }
    },
    computed: {
        sender() {
            if (this.msg.quotedMsg?.fromMe || this.msg.quotedMsg?.type === 'quote_product') return 'Você'

            return this.client?.clientName || ''
        }
    },
    methods: {
        hasFullPath(url) {
            if(url && (url.startsWith('http') || this.isBase64(url)) ) return url
            else {
                const split = url.split('/')
                if(split?.at(2)?.length == 3) // verify if the position 2 (usually it is the channel id) has length of 3, then it means the media comes from Notifique
                    return "https://storage.notifiqueai.com.br/"+url
            }
            return this.mediaURL+url
        },
        goToMessage(msg) {
            if (msg.type === 'quote_product') return

            this.$root.$emit('goToMessage',msg)
        },
        downloadMedia(url) {
            axios({
                url,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL
                    .createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download',`${Date.now()}.png`);
                link.setAttribute('target', '_blank');
                link.click();
            })
        },
        fileExt(filename) {
            if(filename && filename.split('').reverse().join('')) {
                return filename.split('').reverse().join('').split('.')[0].split('').reverse().join('')
            }
            return ''
        },
        isBase64(string) {
            return string.startsWith('/9') || string.startsWith('data:')
        },
    }
}
</script>